:root {
    --main-color: #3BC1CA;
    --dark-blue: #304578;
    --teal: #006979;
    --darkest-blue: #2a3d6d;
    --text-secondary : #6C6C6C;
}

html * {
    font-size: 16px;
    line-height: 1.625;
    font-family: Avenir Book, sans-serif;
}

.display-large {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 80px;
}

.display-medium {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
}

.heading-large {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 52px;
}

.heading-medium {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
}

.title-large {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
}

.title-medium {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}

.title-small {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.text-large {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.text-standard {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.text-small {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.text-extra-small {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
}

.blue-font {
    color: var(--main-color)
}

.dark-blue-font {
    color: var(--dark-blue)
}

.teal-font {
    color: var(--teal)
}

.black-font {
    color: var(--text-main)
}

.grey-font {
    color: var(--text-secondary)
}

@media screen and (max-width: 575px) {

    .display-large {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 44px;
    }

    .display-medium {
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 40px;
    }

    .heading-large {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
    }

    .heading-medium {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
    }

    .title-large {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
    }

    .title-medium {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }

    .text-large {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
    }

    .text-standard {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }

    .text-small {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
}