.separator {
    --block--margin-top:1px;
    --block--margin-bottom: 1.25rem;
    --separator--pointer--offset: 2.5rem;
    --separator--gap: 0.25rem
}

.separator__inner {
    display: flex;
    margin-bottom: 16px;
}

.separator__edge {
    width: 2.5rem !important;
}

.separator__left {
    margin-right: 0.25rem;
}

.separator__left,.separator__right {
    border-bottom: 1px dashed #2a3d6d;
    width: 100%
}

.separator__right {
    margin-left: 0.25rem
}

.separator__pointer {
    border-bottom: 1px dashed #2a3d6d;
    border-right: 1px dashed #2a3d6d;
    flex: 1 0 auto;
    height: 1.25rem;
    transform: rotate(45deg) translate(calc(1.25rem/2.8),calc(1.25rem/2.8));
    width: 1.25rem
}

.blocks>.separator+* {
    margin-top: 0
}