.app-header {
    height: 100px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
}

@media (max-width: 700px) {
    .app-header {position: relative;}
}

.logo {
    height: 100px;
}

.header-kpis {
    display: flex;
    position: sticky;
    align-items: center;
    top: 20px;
}

@media (max-width: 700px) {
    .header-kpis {display: none}
}

.header-kpis-item {
    padding: 0.45rem;
    margin: 0 0.25rem;
}

.nav-icon {
    margin-right: 10px;
    color: var(--dark-blue);
}