.numeric {
    text-align:left;
}

.numeric-padding {
    border: 1px inset #ccc;
}

.numeric-padding input {
    border: none;
    padding: 0px;
    outline: none;
}