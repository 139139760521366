.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    border-radius: 10px;
}

.accordion-section {
    display: flex;
    flex-direction: column;
}

.accordion-active {
    display: block;
}

.accordion-hidden {
    display: none;
}

.accordion-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.accordion-active {
    background-color: #ccc;
}

/* Style the accordion chevron icon */
.accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
    background-color: rgba(247, 247, 247, 1);
    overflow: hidden;
    transition: max-height 0.6s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}