/* General */
.app {
    width: 100%;
    min-height: 100vh;
    transition: 0.2s;
    position: relative;
}

.text-center {
    text-align: center;
}

fieldset {
    border: none;
    padding: 0;
}

ul {
    list-style: none;
}

ol, ul {
    margin: 0;
}

/* Sections */
.slider-group {
    margin-top: 20px;
}

.kpi-chart-container {
    width: 100%;
}

@font-face {
    font-family: "Bebas Neue";
    src: local("Bebas Neue"), url("../fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir Book";
    src: local("Avenir Book"), url("../fonts/AvenirLTStd-Book.otf") format("opentype");
}

/* Summary */
.summary-text-section {
    transition: 0.3s;
}

.summary-selector {
    padding-left: 0;
}

.summary-item {
    display: inline-block;
    margin-left: 16px;
    color: var(--text-secondary);
}

.summary-item:first-child {
    margin-left: 0px;
}

.summary-item:hover {
    cursor: pointer;
}

.summary-selected {
    color: var(--dark-blue);
}

.summary-about-list {
    list-style: disc;
}

.partner-logos {
    max-height: 100px;
    max-width: 200px;
}