#interventions {
    margin: 0;
    padding: 0;
}

.nested {
    display: none;
}

.tree-active {
    display: block;
}

.tree-branch {
    margin: 5px 0;
}

.tree-branch-name:hover {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tree-branch-icon {
    margin-right: 15px;
}

.tree-leaf {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}