/* Containers */
.container {
    max-width: 1170px;
    width: calc(100% - 30px);
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 50px auto;
}

.container-wide {
    max-width: 1400px;
    margin: 0 auto;
    width: calc(100vw - 80px);
}

.container-content {
    margin: 30px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

/* General */
.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.align-center {
    align-content: center;
}

.max-width-800 {
    max-width: 800px;
}

/* Grid */
.grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grid-center {
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
}

.grid-split-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
}

@media (max-width: 991px) {
    .grid-split-2 {
        display: inline-block !important;
    }
}

@media (max-width: 768px) {
    .kpi-row {
        display: inline-block !important;
    }

    .kpi-container {
        gap: 0 !important;
    }
}

/* Flex */
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-cols-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.flex-cols-nowrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.flex-card {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
}

.flex-gap-3 {
    gap: 3rem;
}

/* Gaps */
.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.gap-3 {
    gap: 3rem;
}